import Image from '@components/image/image';
import React from 'react';
// interfaces
import { IImage } from '../../interfaces/image';
// styles
import styles from './hero-background-image.module.scss';

interface IProps {
  title: string;
  description?: string;
  direction?: string;
  image?: IImage;
  desktopImage: IImage;
  tabletImage: IImage;
  mobileImage: IImage;
  backgroundColor?: string;
  buttonLabel?: string;
  buttonAction?: any;
}

const HeroBackgroundImage: React.FC<IProps> = ({
  title,
  description,
  desktopImage,
  tabletImage,
  mobileImage,
  buttonLabel,
  buttonAction,
}: IProps) => (
  <section className={styles.hero}>
    <div className={styles.visualInner}>
      <Image
        filename={desktopImage.filename_disk}
        filenameTablet={tabletImage.filename_disk}
        filenameMobile={mobileImage.filename_disk}
      />
    </div>
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.heroContent}>
          <h1>{title}</h1>
          <hr className={styles.hrLeftAligned} />
          <p className={styles.subheading}>{description}</p>
          <button className={styles.buttonPrimary} onClick={buttonAction}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  </section>
);

export default HeroBackgroundImage;
