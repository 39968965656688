import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery, navigate, Link } from 'gatsby';
// components
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import SectionStandard from '../../components/section-standard';
import Products from '../../components/products';
import HeroBackgroundImage from '../../components/hero-background-image';
// services
import { trackEvent } from '../../services/tracking/tracking';
// styles
import styles from './health-goals.module.scss';

const HealthGoalsPage = () => {
  const content = useStaticQuery(graphql`
    query HealtGoals {
      directusHealthGoal {
        page_title
        page_description
        hero_title
        hero_description
        hero_button_label
        hero_image_desktop {
          title
          filename_disk
        }
        hero_image_tablet {
          title
          filename_disk
        }
        hero_image_mobile {
          title
          filename_disk
        }
        section_1_title
        section_1_description
        section_1_products {
          id
          title
          subtitle
          description
          price
          image_filename_disk
          button_label
        }
        section_2_title
        section_2_description
        section_2_button_label
        section_2_image {
          filename_disk
        }
      }
    }
  `);

  const { directusHealthGoal } = content;
  const {
    page_title,
    page_description,
    hero_title,
    hero_description,
    hero_button_label,
    hero_image_desktop,
    hero_image_tablet,
    hero_image_mobile,
    section_1_title,
    section_1_description,
    section_1_products,
    section_2_title,
    section_2_description,
    section_2_button_label,
    section_2_image,
  } = directusHealthGoal;

  const [activeProduct, setActiveProduct] = useState();

  const onHeroButtonClick = () => {
    navigate('#products');
  };

  // tracking
  useEffect(() => {
    trackEvent('Product List Viewed', {
      list_id: 'Health goals',
      category: 'Health goals',
    });
  }, []);

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />

      <HeroBackgroundImage
        title={hero_title}
        description={hero_description}
        buttonLabel={hero_button_label}
        buttonAction={onHeroButtonClick}
        desktopImage={hero_image_desktop}
        tabletImage={hero_image_tablet}
        mobileImage={hero_image_mobile}
      />

      <section id="products">
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.contentProducts}>
              <h2>{section_1_title}</h2>
              <hr />
              <p className={styles.subheading}>{section_1_description}</p>
            </div>
          </div>

          <Products products={section_1_products} />
        </div>
      </section>

      <SectionStandard
        title={section_2_title}
        description={section_2_description}
        background="PrimaryLighter"
        buttonLabel={section_2_button_label}
        buttonAction={() => navigate('/consultation')}
        image={section_2_image}
      />
    </Layout>
  );
};

export default HealthGoalsPage;
